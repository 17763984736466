(function () {
  'use strict';

  angular
    .module('people.view')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('people.view', {
        url: '/:personId',
        templateUrl: 'people/view/view.tpl.html',
        controller: 'PeopleViewCtrl',
        controllerAs: 'vm'
      })
  }
}());
